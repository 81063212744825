<template>
  <el-row class="view" :gutter="25">
    <el-col class="data" :span="11" :xs="24">
      <div class="data-box">
        <div class="title">Status</div>
        <el-row :gutter="5">
          <el-col :span="12" :xs="24">
            <div class="data-title">SAP AUM</div>
            <div class="data-num">123456</div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="data-title">SYP Staked</div>
            <div class="data-num">
              {{ Math.round(stakingValue).toLocaleString() }}
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="data-title">MarketCap</div>
            <div class="data-num">
              {{ (marketCapChange * 100).toFixed(2) }} %
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="data-title">Volume 24H</div>
            <div class="data-num">$ {{ volume.toLocaleString() }}</div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="data-title">Circulating Supply</div>
            <div class="data-num">
              {{ circulatingSupply.toLocaleString() }}
            </div>
          </el-col>
          <el-col :span="12" :xs="24">
            <div class="data-title" style="height: 21px"></div>
            <div class="data-num">
              {{ ((circulatingSupply / maxSupply) * 100).toFixed(2) }} %
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12" :xs="24">
            <div class="item-btn btn large">Trade SYP</div>
          </el-col>
          <el-col :span="12" :xs="24">
            <router-link to="/staking">
              <div class="item-btn btn large">Start Earning</div>
            </router-link>
          </el-col>
          <el-col :span="12" :xs="24">
            <router-link to="/investment">
              <div class="item-btn btn large">Mint SAP</div>
            </router-link>
          </el-col>
          <el-col :span="12" :xs="24">
            <router-link to="/ai-indicators">
              <div class="item-btn btn-c large">Try AI-Indicators</div>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col class="overview" :span="13" :xs="24">
      <div class="select-box mb">
        <el-button-group>
          <el-button
            type="primary"
            v-for="(item, index) in chartTimeList"
            :key="index"
            :class="{ 'chart-time-at': chartTimeSelect == item.value }"
            @click="selectChartTime(item.value)"
          >
            {{
              item.label.slice(2, 3) == "M" || item.label.slice(2, 3) == "Y"
                ? item.label.slice(0, 3)
                : item.label
            }}
          </el-button>
        </el-button-group>
      </div>
      <div class="select-box pc">
        <el-button-group>
          <el-button
            type="primary"
            v-for="(item, index) in chartTimeList"
            :key="index"
            :class="{ 'chart-time-at': chartTimeSelect == item.value }"
            @click="selectChartTime(item.value)"
          >
            {{ item.label }}
          </el-button>
        </el-button-group>
      </div>
      <div class="chart-box">
        <div id="price-chart" style="width: 100%; height: 100%"></div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { getTotalStaking as getTotalStakingOld } from "../assets/js/stakingv2/staking";
import { stakingList, getTotalStaking } from "../assets/js/stakingv2.1/staking";
import { Connection, PublicKey } from "@solana/web3.js";
import { getAssetValue, getAssetValueSY3 } from "../assets/js/SYcalculate";
import { rpcConfig, rpcUrl } from "../assets/js";
import { getVestingRewardVault } from "../assets/js/vesting2";

export default {
  name: "Dashboard",
  data() {
    return {
      connection: undefined,
      chartTimeList: [
        { label: "DAY", value: "day" },
        { label: "WEEK", value: "week" },
        { label: "1 MONTH", value: "1month" },
        { label: "3 MONTH", value: "3month" },
        { label: "1 YEAR", value: "1year" },
        { label: "ALL", value: "all" },
      ],
      chartTimeSelect: "day",
      chartData: [],
      chartLoading: true,
      price: 0,
      priceChange: 0,
      marketCap: 0,
      marketCapChange: 0,
      volume: 0,
      volumeChange: 0,
      circulatingSupply: 0,
      maxSupply: 1,
      sapValue: 0,
      stakingAmount: 0,
      stakingList: stakingList,
      priceChart: {},
    };
  },
  methods: {
    selectChartTime(data) {
      this.chartTimeSelect = data;
    },
    gotoStaking(data) {
      this.$router.push({
        name: "Staking",
        params: { tab: "staking", lockday: data.toString() },
      });
    },
    gotoFarming() {
      this.$router.push({ name: "Staking", params: { tab: "farming" } });
    },
    async getSYPData() {
      const res = await this.$axios.post("/getSYPData");
      if (res.data) {
        if (res.data.code == 1) {
          const sypData = res.data.data;
          // receive data
          let price0 = sypData.priceList[0].value;
          let price1 = sypData.priceList[sypData.priceList.length - 1].value;
          let marketCap0 = sypData.marketCapList[0].value;
          let marketCap1 = sypData.marketCapList[1].value;
          let volume0 = sypData.volumeList[0].value;
          let volume1 = sypData.volumeList[1].value;
          let maxSupply = sypData.maxSupply.value;
          let circulatingSupply = sypData.circulatingSupply.value;
          // put data
          this.price = price0;
          this.priceChange = (price0 - price1) / price1;
          this.marketCap = marketCap0;
          this.marketCapChange = (marketCap0 - marketCap1) / marketCap1;
          this.volume = volume0;
          this.volumeChange = (volume0 - volume1) / volume1;
          this.maxSupply = maxSupply;
          this.circulatingSupply = circulatingSupply;
        } else {
          const error = res.data.error;
          console.error("get syp data error", error);
        }
      } else {
        console.error("get syp data error");
      }
      let sapAmount = await this.connection.getTokenSupply(
        new PublicKey("FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ")
      );
      let spaBalance = await this.connection.getTokenAccountBalance(
        new PublicKey("5URTP2LHJd8E21GnvYhXByJHWyAH2yRTM26SzhjWLG6A")
      );
      this.maxSupply = sapAmount.value.uiAmount;
      this.circulatingSupply =
        this.maxSupply - spaBalance.value.uiAmount.toFixed(2);
    },
    async getSYPPriceChart() {
      var startDate = new Date().getTime();
      if (this.chartTimeSelect == "day") {
        startDate -= 1000 * 3600 * 24;
      } else if (this.chartTimeSelect == "week") {
        startDate -= 1000 * 3600 * 24 * 7;
      } else if (this.chartTimeSelect == "1month") {
        startDate -= 1000 * 3600 * 24 * 30;
      } else if (this.chartTimeSelect == "3month") {
        startDate -= 1000 * 3600 * 24 * 90;
      } else if (this.chartTimeSelect == "1year") {
        startDate -= 1000 * 3600 * 24 * 365;
      } else if (this.chartTimeSelect == "all") {
        startDate = new Date("2021-11-1").getTime();
      }
      const res = await this.$axios.post("/getSYPPriceChart", { startDate });
      if (res.data) {
        if (res.data.code) {
          let temp = res.data.data;
          temp.reverse();
          var dateList;
          if (this.chartTimeSelect == "day") {
            dateList = temp.map((e) => {
              let date = new Date(e.time).toISOString();
              let temp = date.split("T")[1].split(":");
              let hours = temp[0];
              let mins = temp[1];
              return hours + ":" + mins;
            });
          } else {
            dateList = temp.map((e) => {
              let date = new Date(e.time).toISOString();
              let temp = date.split("T")[0];
              // return date.getFullYear() + '-' + (date.getMonth() + 1).toString() + '-' + date.getDate();
              return temp;
            });
          }
          let dataList = temp.map((e) => {
            return e.value;
          });
          this.chartInit(dateList, dataList);
        } else {
          console.error("get syp price chart error", res.data.error);
        }
      } else {
        console.error("get syp price chart error");
      }
    },
    chartInit(date, data) {
      let dataMin = data[0];
      for (let i = 0; i < data.length; i++) {
        if (data[i] < dataMin) {
          dataMin = data[i];
        }
      }
      dataMin = (dataMin - 0.001).toFixed(3);
      this.priceChart.setOption({
        backgruondColor: "#14151a",
        grid: {
          bottom: 30,
          left: 60,
          top: 30,
          right: 30,
        },
        tooltip: {
          backgroundColor: "#1b152b",
          borderWidth: "0",
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: date,
          boundaryGap: false,
          axisLabel: {
            show: true,
          },
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "value",
          min: dataMin,
          axisLabel: {
            show: true,
            formatter: function (value, index) {
              return value.toFixed(4);
            },
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              color: "#25242b",
            },
          },
        },
        series: [
          {
            data: data,
            type: "line",
            itemStyle: {
              opacity: 0,
              color: "#13eff9",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "rgba(54,55,135,0.7)" },
                  { offset: 1, color: "rgba(54,55,135,0.2)" },
                ],
              },
              // color: 'rgba(54,55,135,0.7)'
            },
          },
        ],
      });
      window.onresize = this.priceChart.resize;
    },
    async getStakingAmount() {
      // this.connection = this.$store.state.walletConnectInfo.connection;
      this.connection = new Connection(rpcUrl, rpcConfig);
      let amount1 = await getTotalStakingOld(this.connection);
      let amount2 = await getTotalStaking(this.connection);
      let amount3 = 0.0;
      {
        let res = await getVestingRewardVault(this.connection);
        if (res.code == 1) {
          amount3 = res.data;
        }
      }
      this.stakingAmount = amount1 + amount2 + amount3;
    },

    async getSAPs() {
      let res = await this.$axios.post("/getFundList", { net: 0 });
      res.data.data.forEach(async (product) => {
        if (product.type == 0) {
          let res1 = await getAssetValue(this.connection, product);
          this.sapValue += res1[3];
        } else if (product.type == 1) {
          let [res1, res2] = await getAssetValueSY3(this.connection, product);
          this.sapValue += res1[3];
          this.sapValue += res2[3];
        }
      });
    },
  },
  mounted() {
    this.priceChart = this.$echarts.init(
      document.getElementById("price-chart")
    );
    this.getSYPData();
    this.getSYPPriceChart();
    this.getStakingAmount();
    this.getSAPs();
  },
  computed: {
    stakingValue() {
      // return this.stakingAmount * this.price;
      return this.stakingAmount;
    },
  },
  watch: {
    chartTimeSelect: function () {
      this.getSYPPriceChart();
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.view {
  height: 100%;
  overflow: hidden;
}

.data {
  height: 100%;
}

.data-box {
  height: 100%;
  padding: 50px;
  box-sizing: border-box;
  background-color: #111;
  border-radius: 25px;
  .title {
    font-size: 32px;
  }
  .data-title {
    margin-top: 50px;
    font-size: 14px;
    color: #888;
  }
  .data-num {
    margin-top: 2px;
    font-size: 28px;
    font-weight: bold;
  }
  .item-btn {
    margin-top: 25px;
  }
}

.overview {
  height: 100%;
  display: flex;
  flex-direction: column;

  .select-box {
    margin: 0 auto;
    text-align: center;

    .el-button-group {
      border-radius: 20px;
      background-color: rgb(27, 34, 46);
      white-space: nowrap;

      .el-button {
        border: none;
        border-radius: 20px;
      }

      .el-button--primary {
        background-color: transparent;
      }

      .chart-time-at {
        background-color: #2c2e42;
      }
    }
  }

  .chart-box {
    flex: 1;
    margin-left: 10px;
    margin-top: 25px;
    background-color: rgb(27, 34, 46);
    border-radius: 15px;
  }
}

@media (max-width: 980px) {
  .overview {
    margin-top: 25px;
    /deep/ .el-button {
      padding: 10px;
    }
  }
}
</style>
